import { IRole } from 'core/interfaces'
import * as moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import injectGoogleMaps from 'utils/injectGoogleMaps'
import { formatPrice } from 'utils/number'
import I18n from '../../../../../core/i18n'
import { IWindow } from '../../../../../core/interfaces'
import { IReservation } from '../../../../../core/interfaces'
import { IJsonResponse } from '../../../../../core/JsonApiSerializer'
import { reservationService } from '../../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  HEADER_HEIGHT,
} from '../../../../../static/constants'
import { Button, Panel } from '../../../../atoms'
import Nav, { ActiveTypes } from '../../../admin/Nav'
import { getDeliveryState } from '../../index'
import ApproveModal from './ApproveModal'
import StatusModal from './StatusModal'

declare var window: IWindow
interface IProps {
  role?: IRole
  model: ActiveTypes
  reservations: {
    reservations: IJsonResponse
  }
  isAdmin: boolean
}

const HostShowIndex: React.FC<IProps> = props => {
  // const { data: reservation } = reservationService.getDataFromJson(props.reservation)
  // const reservationItems = reservation.reservation_items

  const { reservations: initialReservation } = reservationService.getReservationsFromJson(
    props.reservations
  )
  const [reservation, setReservation] = React.useState(initialReservation)
  const [deliveryTime, setDeliveryTime] = React.useState<number>(0)
  const [specifiedDeliveryTime, setSpecifiedDeliveryTime] = React.useState<{
    label: string
    data: {
      DD: string
      HH: string
      mm: string
    }
  }>(null)

  React.useEffect(() => {
    const specifiedDeliveryMoment = moment(reservation.specified_delivery_time_at)
    const newSpecifiedDeliveryTime = {
      label: specifiedDeliveryMoment.format('YYYY-MM-DD HH:mm'),
      data: {
        DD: specifiedDeliveryMoment.format('DD'),
        HH: specifiedDeliveryMoment.format('HH'),
        mm: specifiedDeliveryMoment.format('mm'),
      },
    }
    setSpecifiedDeliveryTime({ ...newSpecifiedDeliveryTime })
  }, [reservation])

  // const setDuration = (post, guest) => {
  //   const distanceMatrixService = new google.maps.DistanceMatrixService()

  //   distanceMatrixService.getDistanceMatrix(
  //     {
  //       origins: [post],
  //       destinations: [guest],
  //       travelMode: google.maps.TravelMode.DRIVING,
  //       drivingOptions: {
  //         departureTime: new Date(),
  //         trafficModel: google.maps.TrafficModel.BEST_GUESS,
  //       },
  //     },
  //     (response, status) => {
  //       if (status === google.maps.DistanceMatrixStatus.OK) {
  //         const results = response.rows[0].elements
  //         const duration = results[0].duration.value
  //         if (duration) {
  //           const minute = Math.round(duration / 60)
  //           setDeliveryTime(minute)
  //         }
  //       }
  //     }
  //   )
  // }

  // React.useEffect(() => {
  //   if (reservation.address && reservation.post.address) {
  //     const { latitude1: postLatitude, longitude1: postLongitude } = reservation.post.address
  //     const { latitude1: gusetLatitude, longitude1: gusetLongitude } = reservation.address
  //     const postLatLng = new google.maps.LatLng(postLatitude, postLongitude)
  //     const userLatLng = new google.maps.LatLng(gusetLatitude, gusetLongitude)

  //     // setDuration(postLatLng, userLatLng)
  //   }
  // }, [google, reservation])

  const handleDecline = React.useCallback(async () => {
    const { reservation: declineReservation, flush } = await reservationService.declineReservation(
      reservation.id
    )
    window.globalModal.closeModal()

    setReservation(declineReservation)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }, [reservation])

  // const handleCancel = React.useCallback(async () => {
  //   const { reservation: canceledReservation, flush } = await reservationService.cancelReservation(
  //     reservation.id
  //   )
  //   window.globalModal.closeModal()

  //   setReservation(canceledReservation)
  //   window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  // }, [reservation])

  // const modifyReservationItemQuantity = async (reservation_item_id, value, index) => {
  //   const params = {
  //     reservation_item: { quantity: value },
  //   }
  //   const {
  //     reservation: modifyReservation,
  //     flush,
  //   } = await reservationService.modifyReservationItemQuantity(reservation_item_id, params)
  //   setReservation(modifyReservation)
  //   window.globalModal.closeModal()
  //   window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  // }

  const showCancelAndDeclineModal = () => {
    window.globalModal.showModal({
      title: `本当に注文をキャンセルしますか？`,
      body:
        reservation.bundled_children && reservation.bundled_children.length > 0
          ? '同送注文（子）があります。本注文をキャンセルすると同送注文（子）も全てキャンセルされます。'
          : null,
      closeText: '操作を取り消す',
      submitText: '注文をキャンセルする',
      submitDanger: true,
      handleSubmit: handleDecline,
    })
  }

  const handleShowApproveModal = async () => {
    window.globalModal.showModal({
      title: I18n.t('reservation.approve_reservation'),
      body: (
        <ApproveModal
          newStatus="approved"
          specifiedDeliveryTime={reservation.specified_delivery_time_at}
          deliveryTime={deliveryTime}
          reservationId={reservation.id}
          setReservation={setReservation}
          timeSpecifiedFlag={!reservation.require_delivery && reservation.time_specified_flag}
        />
      ),
      closeText: '操作を取り消す',
    })
  }

  const setDeliveryStatus = updateDelivery => {
    setReservation({
      ...reservation,
      delivery: updateDelivery,
    })
  }

  const handleShowStatusModal = async () => {
    window.globalModal.showModal({
      title: I18n.t('reservation.update_state'),
      body: (
        <StatusModal
          reservationId={reservation.id}
          deliveryStatus={reservation.delivery.status}
          setDeliveryStatus={setDeliveryStatus}
          isAdmin={props.isAdmin}
        />
      ),
      closeText: '操作を取り消す',
    })
  }

  const titleHead = (
    <S.TitleHeader>
      <span className="header-title">{I18n.t('reservation.detail')}</span>
      {reservation.workflow_state === 'requested' && (
        <Button primary={true} handleClick={handleShowApproveModal}>
          {I18n.t('reservation.approved')}
        </Button>
      )}

      {props.isAdmin &&
        reservation.workflow_state === 'approved' &&
        reservation.delivery.status !== 'completed' && (
          <Button primary={true} handleClick={handleShowStatusModal}>
            {'発注ステータス変更'}
          </Button>
        )}
    </S.TitleHeader>
  )
  return (
    <S.LayoutIndex>
      <Nav active="reservation" role={props.role} />
      <S.Main>
        <Panel title={titleHead}>
          <ul>
            {reservation.bundled_children && reservation.bundled_children.length > 0 && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>同送注文ID（子）</S.ListItemTitle>
                <S.ListItemContent>
                  {reservation.bundled_children.map((bundledId, index) => (
                    <>
                      <a
                        key={index}
                        style={{ textDecoration: 'underline' }}
                        href={`/host/reservations/${bundledId}`}
                        target="_blank"
                      >
                        {bundledId}
                      </a>
                      {index < reservation.bundled_children.length - 1 && ' '}
                    </>
                  ))}
                </S.ListItemContent>
              </S.ListItem>
            )}
            {reservation.bundled_flag &&
              reservation.bundled_id &&
              reservation.workflow_state !== 'declined' && (
                <S.ListItem className="border_bottom">
                  <S.ListItemTitle>同送注文ID（親）</S.ListItemTitle>
                  <S.ListItemContent>
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={`/host/reservations/${reservation.bundled_id}`}
                      target="_blank"
                    >
                      {reservation.bundled_id}
                    </a>
                  </S.ListItemContent>
                </S.ListItem>
              )}
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('reservation.approve_status')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.workflow_state_i18n}</div>
              </S.ListItemContent>
            </S.ListItem>
            {reservation.delivery && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>{I18n.t('reservation.delivery_status')}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>{reservation.delivery.status_i18n}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}

            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('reservation.reservation_id')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.id}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('reservation.user_id')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.user.id}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('reservation.reservation_date')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.ordered_at}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>配送日時</S.ListItemTitle>
              <S.ListItemContent>
                <div>{reservation.display_specified_delivery_time_at}</div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('reservation.reservation_info')}</S.ListItemTitle>
              <S.ListItemContent>
                {reservation.reservation_items.map((item, index) => (
                  <div key={item.id}>
                    <p>
                      <span>{item.name}</span>
                      <span> x </span>
                      <span>
                        {item.quantity}
                        {/* 個数増減一旦非表示に */}
                        {/* {props.isAdmin &&
                          (reservation.workflow_state === 'requested' ||
                            reservation.workflow_state === 'approved') && (
                            <select
                              value={item.quantity}
                              onChange={e => {
                                modifyReservationItemQuantity(item.id, e.target.value, index)
                              }}
                            >
                              {Array.from(Array(100).keys()).map(quantity => (
                                <option key={quantity} value={quantity}>
                                  {quantity}
                                </option>
                              ))}
                            </select>
                          )} */}
                        {/* {props.isAdmin &&
                          reservation.workflow_state !== 'requested' &&
                          reservation.workflow_state !== 'approved' &&
                          item.quantity} */}
                      </span>
                      <span>（{item.jan}）</span>
                    </p>
                    <S.ListItemOption>
                      {item.reservation_item_options.map(option => (
                        <p key={option.id}>
                          <span>{option.option_title}</span>
                          <span> - </span>
                          <span>{option.option_item_title}</span>
                        </p>
                      ))}
                    </S.ListItemOption>
                  </div>
                ))}
              </S.ListItemContent>
            </S.ListItem>
            {reservation.remarks && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>{I18n.t('generic.note')}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>
                    <p className="reservationRemark">{reservation.remarks}</p>
                  </div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('reservation.customer_info')}</S.ListItemTitle>
              <S.ListItemContent>
                <p>{reservation.user.username}</p>
                <p>電話:{reservation.address?.phone}</p>
                {reservation.user.address?.land_line_phone && (
                  <p>固定電話:{reservation.user.address?.land_line_phone}</p>
                )}
                <p>
                  <span>{I18n.t('generic.address')}: </span>
                  {`${reservation.address?.full_address_without_phone}`}
                </p>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('price.subtotal')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>
                  {`¥${reservation.price}`}
                  {reservation.tax > 0 && `（うち消費税 ${formatPrice(reservation.tax)}）`}
                </div>
              </S.ListItemContent>
            </S.ListItem>
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>配送料</S.ListItemTitle>
              <S.ListItemContent>
                <div>{formatPrice(reservation.delivery_fee)}</div>
              </S.ListItemContent>
            </S.ListItem>
            {reservation.used_campaign && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>{reservation.campaign_name}</S.ListItemTitle>
                <S.ListItemContent>
                  <div>-¥{reservation.used_campaign}</div>
                </S.ListItemContent>
              </S.ListItem>
            )}
            {reservation.used_coupon && (
              <S.ListItem className="border_bottom">
                <S.ListItemTitle>クーポン</S.ListItemTitle>
                <S.ListItemContent>
                  {reservation.coupons.map((coupon, index) => (
                    <p key={index}>
                      <span>-{formatPrice(coupon.value)}</span>
                      <span>(コード / {coupon.code})</span>
                    </p>
                  ))}
                </S.ListItemContent>
              </S.ListItem>
            )}
            <S.ListItem className="border_bottom">
              <S.ListItemTitle>{I18n.t('price.total')}</S.ListItemTitle>
              <S.ListItemContent>
                <div>¥{reservation.total_price}</div>
              </S.ListItemContent>
            </S.ListItem>
          </ul>
          <S.Buttons>
            <div>
              <Button>
                <a href={`/${props.isAdmin ? 'admin' : 'host'}/reservations/`}>注文履歴一覧へ</a>
              </Button>
              {reservation.receipt && (
                <Button>
                  <a href={`/receipts/${reservation.receipt.id}`} target="_blank">
                    {I18n.t('generic.receipt')}
                  </a>
                </Button>
              )}
              {reservation.purchase_statement && (
                <Button>
                  <a
                    href={`/purchase_statements/${reservation.purchase_statement.id}`}
                    target="_blank"
                  >
                    {I18n.t('generic.purchase_statement')}
                  </a>
                </Button>
              )}
            </div>
            {(reservation.workflow_state === 'requested' ||
              reservation.workflow_state === 'approved') && (
              <div>
                <Button handleClick={showCancelAndDeclineModal}>
                  {I18n.t('reservation.declined')}
                </Button>
              </div>
            )}
          </S.Buttons>
        </Panel>
      </S.Main>
    </S.LayoutIndex>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.TitleHeader = styled.div`
  font-size: 18px;
  button {
    margin-left: 24px;
  }
`
S.LayoutIndex = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
  @media print {
    font-size: 12px;
  }
`

S.Main = styled.section`
  flex: 1;
  padding: 16px;
  overflow: auto;
`

S.ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  &.border_bottom {
    border-bottom: solid 1px ${COLORS.Border};
    padding-bottom: 20px;
  }

  .reservationRemark {
    font-weight: bold;
    color: red;
  }

  @media print {
    flex-wrap: nowrap;
    margin-top: 0px;
  }
`

S.ListItemTitle = styled.div`
  width: 40%;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-top: 16px;
  }

  @media print {
    width: 20%;
  }
`

S.ListItemContent = styled.div`
  width: 60%;
  white-space: pre-wrap;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-top: 8px;
  }
  @media print {
    width: 80%;
  }
`
S.Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    > div {
      margin: 8px 0;
    }
  }

  @media print {
    display: none;
  }
`
S.Declined = styled.div`
  text-align: center;
  color: ${COLORS.Danger};
  margin-top: 30px;
  border: solid 1px;
  padding: 8px;

  @media print {
    display: none;
  }
`

S.ListItemOption = styled.div`
  font-size: 14px;
  padding-left: 16px;
`

export default injectGoogleMaps(HostShowIndex)
