import * as moment from 'moment'
import { any } from 'prop-types'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IItemCategory, IOptionItem, IPostItem, IPostOption } from '../../../../../core/interfaces'
import { postItemService } from '../../../../../core/services'
import {
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../../static/constants'
import { Button, CheckBox, InputText, InputTextArea, InputTextV2, Select } from '../../../../atoms'
import { DropZoneUploader } from '../../../../atoms'
import { Form } from '../../../../molecules'
import { FlashMessage } from '../../../../organisms'
import { FileSelectUploader } from '../../../../organisms'
import { OptionForm } from '../../../../organisms'
import { PostContext } from './'
import buildFormInputName from './functions/buildFormInputName'
interface IWindow {
  flashMessages: FlashMessage
  addEventListener: any
  removeEventListener: any
}
declare var window: IWindow

{
  /* こちらに含まれた Name しか submit されない */
}
const MENU_FIELDS = {
  name: 'name',
  description: 'description',
  price: 'price',
  retail_price: 'retail_price',
  settlement_price: 'settlement_price',
  tax_rate: 'tax_rate',
  quantity: 'quantity',
  is_quantity_limited: 'is_quantity_limited',
  quantity_limited_status: 'quantity_limited_status',
  item_category_id: 'item_category_id',
  jan: 'jan',
  allergy: 'allergy',
}
interface IProps {
  item: IPostItem
  postId: number
  itemCategories: IItemCategory[]
  handleBack(): void
  updateItem(item: IPostItem): void
}
interface IErrors {
  [key: string]: string | null
}
interface IImage {
  image_url: string
  file?: any
}
interface IOptionFormData {
  title: string
  description: string
  require: boolean
  multiple: boolean
}
interface IItemImage {
  id: null | number
  url: string
  key: number
  file?: any
}

const ALLERGIES = ['卵', '乳成分', '小麦', 'そば', '落花生', 'かに', 'えび', 'くるみ']

const PostItemEdit: React.FC<IProps> = props => {
  const { items, setItems } = React.useContext(PostContext)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [limitedItem, setLimitedItem] = React.useState<boolean>(props.item.is_quantity_limited)
  const quantityRef = React.createRef<HTMLInputElement>()
  const [price, setPrice] = React.useState(props.item.price ? String(props.item.price) : '0')
  const [retailPrice, setRetailPrice] = React.useState(
    props.item.retail_price ? String(props.item.retail_price) : '0'
  )
  const [settlementPrice, setSettlementPrice] = React.useState(
    props.item.settlement_price ? String(props.item.settlement_price) : '0'
  )
  const [taxRate, setTaxRate] = React.useState(props.item.tax_rate)
  const [calcTaxRate, setCalcTaxRate] = React.useState(props.item.tax_rate === 'ten' ? 1.1 : 1.08)
  const [errors, setErrors] = React.useState<IErrors>({})
  const [image, setImage] = React.useState<IImage>(
    props.item.image_url ? { image_url: props.item.image_url } : null
  )

  const [itemCategory, setItemCategory] = React.useState<IItemCategory>(
    props.item.item_category_id
      ? props.itemCategories.find(item => item.id === props.item.item_category_id)
      : null
  )

  const ancestry = itemCategory
    ? itemCategory.ancestry
      ? itemCategory.ancestry.split('/')
      : []
    : []

  const [largeCategories, setLargeCategories] = React.useState<IItemCategory[]>(
    props.itemCategories.filter(item => item.parent_id == null)
  )

  const [mediumCategories, setMediumCategories] = React.useState<IItemCategory[]>(
    itemCategory && ancestry[0]
      ? props.itemCategories.filter(item => item.parent_id === Number(ancestry[0]))
      : []
  )

  const [smallCategories, setSmallCategories] = React.useState<IItemCategory[]>(
    itemCategory && ancestry[1]
      ? props.itemCategories.filter(item => item.parent_id === Number(ancestry[1]))
      : []
  )

  const handleLargeCategoryChange = e => {
    const largeCategoryId = e.currentTarget.value
    setMediumCategories(
      props.itemCategories.filter(item => item.parent_id === Number(largeCategoryId))
    )
    setSmallCategories([])
  }

  const handleMediumCategoryChange = e => {
    const mediumCategoryId = e.currentTarget.value
    setSmallCategories(
      props.itemCategories.filter(item => item.parent_id === Number(mediumCategoryId))
    )
  }

  const [menuFields, setMenuFields] = React.useState<any>(MENU_FIELDS)
  // MENU_FIELDS 生成処理
  buildFormInputName(props.item, menuFields)

  // 税率など価格関連
  React.useEffect(() => {
    setCalcTaxRate(taxRate === 'ten' ? 1.1 : 1.08)
  }, [taxRate])

  React.useEffect(() => {
    calcTaxPrice(price, calcTaxRate)
  }, [calcTaxRate])

  const customCeil = value => {
    return value % 1 === 0 ? value : Math.ceil(value)
  }
  const customFloor = value => {
    return value % 1 === 0 ? value : Math.floor(value)
  }

  const handleTaxRateChange = e => {
    setTaxRate(e.target.value)
  }

  const handlePriceNoTaxChange = e => {
    setPrice(e.target.value.toString())
    calcTaxPrice(e.target.value, calcTaxRate)
  }

  const calcTaxPrice = (inputPrice, inputCalcTaxRate) => {
    let priceNoTax
    if (inputPrice === '0') {
      priceNoTax = Math.floor(parseFloat(retailPrice) / inputCalcTaxRate)
      setPrice(priceNoTax.toString())
    } else {
      priceNoTax = Number(inputPrice)
    }
    const priceWithTax = priceNoTax * inputCalcTaxRate
    const priceWithTaxCeil = customCeil(parseFloat(priceWithTax.toFixed(2)))
    const priceWithTaxFloor = customFloor(parseFloat(priceWithTax.toFixed(2)))

    setPrice(Number(priceNoTax).toString())
    setRetailPrice(Number(priceWithTaxCeil).toString())
    setSettlementPrice(Number(priceWithTaxFloor).toString())
  }

  const [allergies, setAllergies] = React.useState<string[]>(props.item.allergy || [])

  const currentImages = props.item.post_item_images || []
  const initialImages = currentImages.map((img: any) => ({
    id: img.id,
    url: img.image_url,
    key: img.id,
  }))

  const [images, setImages] = React.useState<IItemImage[]>(initialImages)
  const [deletedImages, setDeletedImages] = React.useState<IItemImage[]>([])

  const setQuantityLimited = e => {
    setLimitedItem(e.target.checked)
    if (e.target.checked === true) {
      quantityRef.current.focus()
    }
  }

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const imageItemsToUpload = React.useRef(images)
  const deletedImagesSend = React.useRef(deletedImages)

  React.useEffect(() => {
    imageItemsToUpload.current = images
  }, [images])

  React.useEffect(() => {
    deletedImagesSend.current = deletedImages
  }, [deletedImages])

  const handleItemFormSubmit = async (initialValues, values) => {
    const params = { ...values }
    const currentValues = {}
    Object.keys(values).forEach(key => {
      currentValues[key] = String(values[key])
    })
    if (params.price) {
      params.price = Number(String(params.price).replace(/[^0-9]/g, ''))
    }
    if (params.retail_price) {
      params.retail_price = Number(String(params.retail_price).replace(/[^0-9]/g, ''))
    }
    if (params.settlement_price) {
      params.settlement_price = Number(String(params.settlement_price).replace(/[^0-9]/g, ''))
    }
    if (params.quantity) {
      params.quantity = Number(String(params.quantity).replace(/[^0-9]/g, ''))
    }
    if (props.item.id) {
      params.id = props.item.id
    }
    params.allergy = allergies

    setIsSubmitting(true)

    try {
      if (!params.id) {
        const { edit_post_path, postItem } = await postItemService.create(
          params,
          imageItemsToUpload.current,
          props.postId
        )
        location.href = edit_post_path
      } else {
        const { edit_post_path, flush, postItem } = await postItemService.update(
          params,
          imageItemsToUpload.current,
          deletedImagesSend.current,
          props.postId
        )
        location.href = edit_post_path
      }
    } catch (error) {
      console.error(error)
    } finally {
      // 画面遷移するまでtrueのままにしておく
      setIsSubmitting(true)
    }
  }

  const handleFileSelect = event => {
    const newImage = event.target.files[0]
    const fileReader = new FileReader()
    fileReader.onloadend = (eventRoader: any) => {
      setImage({
        file: newImage,
        image_url: eventRoader.target.result,
      })
    }
    fileReader.readAsDataURL(newImage)
  }

  // オプション単位追加
  const addPostOption = () => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          // オプション追加
          tmp_item.post_options.push({
            post_item_id: undefined,
            title: '',
            description: '',
            is_required: true,
            is_multiple: true,
            option_items: [],
            deleted_at: '',
          })
        }
      })
      return newState
    })
  }

  // オプション単位削除
  const removePostOption = (option_index: number) => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          // 削除は論理削除。該当削除オブジェクトに現在日時をセット
          tmp_item.post_options[option_index].deleted_at = moment().format('YYYY-MM-DD HH:mm:ssZ')
        }
      })
      return newState
    })
    // 削除に合わせて関連エラー削除しないと更新ボタンが押せなくなるため
    delete errors[`post_options_attributes[${option_index}][title]`]
    delete errors[`post_options_attributes[${option_index}][description]`]
    delete errors[`post_options_attributes[${option_index}][is_required]`]
    delete errors[`post_options_attributes[${option_index}][is_multiple]`]
  }

  // オプションアイテム単位追加
  const addOptionItem = (index: number) => () => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          // 該当のオプションにアイテム追加
          tmp_item.post_options[index].option_items.push({
            post_option_id: undefined,
            title: '',
            price: 0,
            retail_price: undefined,
            settlement_price: undefined,
            tax_rate: '',
            is_quantity_limited: false,
            quantity_limited_status: '',
            quantity: undefined,
            deleted_at: '',
            jan: '',
          })
        }
      })
      return newState
    })
  }

  // オプションアイテム単位削除
  const removeOptionItem = (option_index: number, item_index: number) => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          // 削除は論理削除。該当削除オブジェクトに現在日時をセット
          tmp_item.post_options[option_index].option_items[item_index].deleted_at = moment().format(
            'YYYY-MM-DD HH:mm:ssZ'
          )
        }
      })
      return newState
    })

    // 削除に合わせて関連エラー削除しないと更新ボタンが押せなくなるため
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][title]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][price]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][retail_price]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][settlement_price]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][tax_rate]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][jan]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][quantity]`
    ]
  }
  const postListBack = () => {
    setItems(prev => {
      const newState = [...prev]
      return newState.filter(item => item.id)
    })
    props.handleBack()
  }

  const setAllergy = e => {
    let newAllergies = allergies
    const value = e.target.value
    if (e.target.checked === true) {
      newAllergies.push(value)
    } else {
      newAllergies = newAllergies.filter(element => {
        return element !== value
      })
    }
    setAllergies(newAllergies)
  }

  const deletePostItemImage = img => {
    const nextImages = images.filter(c_image => c_image.key !== img.key)
    setImages(nextImages)
    if (img.id !== null) {
      setDeletedImages([...deletedImages, ...[img]])
    }
  }

  const uploadedImages = []
  const updateItemImages = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()
      fileReader.onloadend = (event: any) => {
        const uploadedImage = {
          id: null,
          url: event.target.result,
          key: new Date().getTime(),
          file: addedImage,
        }
        uploadedImages.push(uploadedImage)
        const nextImages = [...images, ...uploadedImages]
        setImages(nextImages)
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  return (
    <>
      <Form
        fields={menuFields}
        handleUpdateForm={handleUpdateForm}
        handleSubmit={handleItemFormSubmit}
        // customValidation={customValidation}
      >
        <ul>
          <S.FormItem>
            <InputText
              required={true}
              name="name"
              label={I18n.t('meta.post.name_menu')}
              defaultValue={props.item.name}
              error={errors.name}
            />
          </S.FormItem>
          <S.FormComment>*最大100文字</S.FormComment>
          <S.FormItem>
            <InputText
              required={false}
              name="jan"
              label={I18n.t('meta.post.jan')}
              defaultValue={props.item.jan}
              error={errors.jan}
            />
          </S.FormItem>
          <S.FormComment>* オプションが有る場合はオプションに入力してください。</S.FormComment>
          <S.FormItem>
            <InputTextArea
              required={true}
              name="description"
              label={I18n.t('meta.post.description_menu')}
              defaultValue={props.item.description}
              error={errors.description}
              maxLength={1000}
            />
            <S.FormComment>*最大1000文字</S.FormComment>
          </S.FormItem>
          <S.FormItem>
            <Select
              required={true}
              name="tax_rate"
              label={I18n.t('generic.tax_rate')}
              options={[
                { label: '8%', value: 'eight' },
                { label: '10%', value: 'ten' },
              ]}
              initialText={I18n.t('meta.post.set_tax_rate')}
              defaultValue={props.item.tax_rate ? props.item.tax_rate.toString() : ''}
              onChangeHandler={handleTaxRateChange}
            />
          </S.FormItem>
          <S.FormItem>
            <InputTextV2
              required={true}
              inputType="number"
              name="price"
              label={I18n.t('generic.price_no_tax')}
              value={price}
              error={errors.price}
              onChangeHandler={handlePriceNoTaxChange}
            />
          </S.FormItem>
          <S.FormItem>
            <InputTextV2
              required={false}
              name="settlement_price"
              label={I18n.t('generic.settlement_price')}
              value={settlementPrice}
              error={errors.settlement_price}
              readonly={true}
            />
          </S.FormItem>
          <S.FormItem>
            <InputTextV2
              required={false}
              name="retail_price"
              label={I18n.t('generic.retail_price')}
              value={retailPrice}
              error={errors.retail_price}
              readonly={true}
            />
          </S.FormItem>
          <S.FormItem>
            <Select
              required={true}
              name="item_large_category_id"
              label={I18n.t('meta.post.large_category')}
              options={largeCategories.map(item => {
                return { label: item.name, value: item.id }
              })}
              initialText={I18n.t('meta.post.set_large_category')}
              defaultValue={itemCategory && ancestry[0] ? ancestry[0] : ''}
              onChangeHandler={handleLargeCategoryChange}
            />
          </S.FormItem>
          <S.FormItem>
            <Select
              required={true}
              name="item_medium_category_id"
              label={I18n.t('meta.post.medium_category')}
              options={mediumCategories.map(item => {
                return { label: item.name, value: item.id }
              })}
              initialText={I18n.t('meta.post.set_medium_category')}
              defaultValue={itemCategory && ancestry[1] ? ancestry[1] : ''}
              onChangeHandler={handleMediumCategoryChange}
            />
          </S.FormItem>
          <S.FormItem>
            <Select
              required={true}
              name="item_category_id"
              label={I18n.t('meta.post.small_category')}
              options={smallCategories.map(item => {
                return { label: item.name, value: item.id }
              })}
              initialText={I18n.t('meta.post.set_small_category')}
              defaultValue={
                props.item.item_category_id ? props.item.item_category_id.toString() : ''
              }
              error={errors.item_category}
            />
          </S.FormItem>
          <S.FormItem>
            <CheckBox
              label={I18n.t('post.quantity')}
              name="is_quantity_limited"
              defaultChecked={props.item.is_quantity_limited}
              onChangeHandler={setQuantityLimited}
            />
          </S.FormItem>
          <S.FormItem>
            <Select
              name="quantity_limited_status"
              label={I18n.t('post.quantity_limited_status')}
              options={[
                { label: '24H', value: 'one_day' },
                { label: 'トータル', value: 'total' },
              ]}
              defaultValue={
                props.item.quantity_limited_status
                  ? props.item.quantity_limited_status.toString()
                  : ''
              }
              disabled={!limitedItem}
            />
          </S.FormItem>
          <S.FormItem>
            <InputText
              name="quantity"
              inputType="number"
              label={I18n.t('post.max_nb_orders')}
              defaultValue={props.item.quantity ? String(props.item.quantity) : '0'}
              error={errors.quantity}
              readonly={!limitedItem}
              inputRef={quantityRef}
            />
          </S.FormItem>
          {limitedItem && (
            <S.FormItem>
              <S.DeliveryFeeWrapper>
                <S.FormItemTitle>{I18n.t('post.remaining_stock')}</S.FormItemTitle>
                <div>{props.item.remaining_stock ? String(props.item.remaining_stock) : '0'}</div>
              </S.DeliveryFeeWrapper>
            </S.FormItem>
          )}
          <S.FormItem>
            <S.FormCheckBox>
              <S.FormItemTitle>アレルギー</S.FormItemTitle>
              <S.CheckBoxList>
                {ALLERGIES.map((allergy, index) => (
                  <div key={index}>
                    <CheckBox
                      label={allergy}
                      name="allergy"
                      defaultChecked={props.item.allergy?.includes((index + 1).toString())}
                      showLabelInline={true}
                      onChangeHandler={setAllergy}
                      value={(index + 1).toString()}
                    />
                  </div>
                ))}
              </S.CheckBoxList>
            </S.FormCheckBox>
          </S.FormItem>
          <S.FormItem>
            <S.ImageWrapper>
              <S.FormItemTitle>{I18n.t('generic.image')}</S.FormItemTitle>
              <S.ImageItemWrapper hasNoImage={images.length === 0}>
                {images.map((img, index) => (
                  <S.ImageItem key={index} main={index === 0}>
                    <S.ImageItemBody>
                      <img src={img.url} draggable={false} loading="lazy" decoding="async" />
                      <S.ImageItemDelete className="ImageItemDelete">
                        <span onClick={() => deletePostItemImage(img)}>
                          {I18n.t('generic.delete')}
                        </span>
                      </S.ImageItemDelete>
                    </S.ImageItemBody>
                  </S.ImageItem>
                ))}
                <DropZoneUploader onDrop={updateItemImages} />
              </S.ImageItemWrapper>
            </S.ImageWrapper>
          </S.FormItem>
          {/* オプションコンポーネント */}
          <OptionForm
            item={props.item}
            addPostOption={addPostOption}
            removePostOption={removePostOption}
            postOptions={props.item.post_options}
            addOptionItem={addOptionItem}
            removeOptionItem={removeOptionItem}
            errors={errors}
          />
          {/* オプションコンポーネント */}
        </ul>
        <S.Footer>
          <S.ButtonLeft>
            <Button handleClick={postListBack}>{I18n.t('generic.back_to_index')}</Button>
          </S.ButtonLeft>
          <S.ButtonSave>
            <Button primary={true} disabled={!isSubmitEnabled || isSubmitting}>
              {isSubmitting
                ? I18n.t('generic.sending')
                : props.item.id
                ? I18n.t('generic.update')
                : I18n.t('generic.create')}
            </Button>
          </S.ButtonSave>
        </S.Footer>
      </Form>
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  display: block;
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.FormItemTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`
S.Footer = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
`
S.DropZoneWrapper = styled.div<{ hasImage: boolean }>`
  display: block;
  width: 100%;
  flex: 1;
  ${({ hasImage }) =>
    hasImage &&
    `
    display: none;
  `}
`
S.ButtonLeft = styled.div`
  margin-right: 20px;
`

S.ButtonSave = styled.div`
  button {
    width: 100%;
  }
`

S.DeliveryFeeWrapper = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.ImageWrapper = styled.div<{ hasNoImage: boolean }>`
  display: flex;
  .DropZoneUploader {
    width: 100%;
    flex: 1;
    margin: 24px 16px 0;
  }

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.ImageItemWrapper = styled.div<{ hasNoImage: boolean }>`
  width: calc(100% - 240px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  overflow: hidden;
  -webkit-user-select: none;

  ${({ hasNoImage }) =>
    hasNoImage &&
    `
    .DropZoneUploader {
      width: 100%;
      margin: 24px 16px 0;
    }
  `}

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  .DropZoneUploader {
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: calc(50% - 32px);
    }
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      width: calc(100% - 32px);
    }
  }
`

S.ItemImage = styled.div`
  display: block;
  position: relative;
  width: 240px;
  height: inherit;
  background-color: rgb(244, 244, 244);
  border-radius: 3px;
  overflow: hidden;
  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 200px;
  }
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
S.PreviewImage = styled.div`
  display: flex;
  flex: 1;
`
S.ImageButtons = styled.div`
  display: flex;
  flex: 1;
  max-width: 100px;
  text-align: center;
  margin-left: 50px;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-left: 20px;
  }
`

S.FileSelectWrapper = styled.div`
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 0 11px;
  }
  border-radius: 4px;
  outline: none;
  background-color: var(${THEME_COLOR_VARIABLE_NAME});
  color: #fff;
  border: none;

  &:hover {
    opacity: 0.6;
  }
  > a {
    color: #fff;
  }

  input {
    width: 0;
  }
`

S.FormCheckBox = styled.div`
  display: flex;
`

S.CheckBoxList = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

S.ImageItem = styled.div<{ dragging?: boolean }>`
  width: calc(50% - 32px);
  margin: 24px 16px 0;

  ${({ dragging }) =>
    dragging &&
    `
    width: 200px;
    height: 200px;
    opacity: 0.8;
    position: fixed;
    background-color: #fff;

    > div {
      height: inherit;
    }
  `}

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: calc(50% - 32px);
  }

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: calc(100% - 32px);
  }

  // &.main {
  //   width: calc(100% - 32px);
  // }

  > textarea {
    padding: 0;
    height: 32px;
    resize: none;
    border: none;
    line-height: 1.5;

    &:focus {
      border: none;
    }
  }
`

S.ImageItemBody = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  border-radius: 4px;
  background-color: #f4f4f4;
  overflow: hidden;

  > span {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px 8px;
    color: #fff;
    background-color: var(${THEME_COLOR_VARIABLE_NAME});
    border-radius: 3px;
    font-size: 12px;
  }

  > img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }

  &:hover {
    .ImageItemDelete {
      transform: translateY(0);
    }
  }
`

S.ImageItemDelete = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 12px;
  color: #fff;
  font-size: 14px;

  @media (any-hover: hover) {
    transform: translateY(100%);
    transition: transform 0.2s ease;
  }

  > span {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
`
export default PostItemEdit
