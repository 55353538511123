import I18n from 'core/i18n'
import { IAddress } from 'core/interfaces'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import injectGoogleMaps from 'utils/injectGoogleMaps'
import {
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  DELIVERY_AREA,
  LAND_LINE_PHONE_INPUT_PATTERN,
  PHONE_INPUT_PATTERN,
  TEL_INPUT_PATTERN,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import { Button, FormItemLayout, InputText, InputTextV2, Spacer, Spinner } from '../atoms'
export interface IAddressFormMethods {
  getAddress(): IAddress
}

interface IProps {
  defaultAddress?: any
  withMap?: boolean
  required?: boolean
  phoneRequired?: boolean
  isGuest?: boolean
  errors?: {
    [key: string]: any
  }
  isBundled?: boolean
  updateForm?(): void
}
const FLASH_TIME = 300
export const ADDRESS_FIELDS = {
  phone: '',
  postal_code1: null,
  state1: null,
  city1: null,
  other1_1: null,
  other1_2: '',
  land_line_phone: '',
} as const

export const HOST_ADDRESS_FIELDS = {
  phone: '',
  emergency_phone: '',
  postal_code1: null,
  state1: null,
  city1: null,
  other1_1: null,
  other1_2: '',
  land_line_phone: '',
} as const

export const emptyToNull = (key, val) => {
  if (key === 'phone' || key === 'other1_2' || key === 'land_line_phone') {
    return val
  }
  return val && val.length === 0 ? null : val
}

const getInitAddress = ({
  phone,
  emergency_phone,
  postal_code1,
  state1,
  city1,
  other1_1,
  other1_2,
  land_line_phone,
}: IAddress) => {
  return {
    phone,
    emergency_phone,
    postal_code1,
    state1,
    city1,
    other1_1,
    other1_2,
    land_line_phone,
  }
}

const removeDashValue = e => {
  const removedDash = e.target.value.split('-').join('')
  e.target.value = removedDash
  return removedDash
}

const AddressForm = React.forwardRef<IAddressFormMethods, IProps>(
  (
    {
      defaultAddress,
      withMap = true,
      errors = {},
      phoneRequired = true,
      isGuest = false,
      updateForm,
      required = false,
      isBundled = false,
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [message, setMessage] = React.useState<string>('')

    const [address, setAddress] = React.useState<IAddress>(getInitAddress({ ...defaultAddress }))
    const [updated, setUpdated] = React.useState<boolean>(false)
    const [disabledAddress, setDisabledAddress] = React.useState<boolean>(true)

    const hasDefaultAddress = !!defaultAddress
    const defaultAddressPostalCode = defaultAddress?.postal_code1
      ? defaultAddress.postal_code1.split('-').join('')
      : ''
    const [deliveryAvailability, setDeliveryAvailability] = React.useState<boolean>(
      hasDefaultAddress ? DELIVERY_AREA.includes(defaultAddressPostalCode) : true
    )

    const onChangeHandle = async e => {
      setIsLoading(true)
      const postCode = e.target.value.split('-').join('')
      const res = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`, {
        mode: 'cors',
      })
      const data = await res.json()
      if (data.status === 200) {
        const result = data.results[0]
        const newAddress = address
        newAddress.postal_code1 = result.zipcode
        newAddress.state1 = result.address1
        newAddress.city1 = result.address2
        newAddress.other1_1 = result.address3
        newAddress.other1_2 = address.other1_2
        setAddress({ ...newAddress })
        setDeliveryAvailability(DELIVERY_AREA.includes(result.zipcode))
        setMessage('')
        setIsLoading(false)
      } else {
        const newAddress = address
        newAddress.postal_code1 = ''
        newAddress.state1 = ''
        newAddress.city1 = ''
        newAddress.other1_1 = ''
        setAddress({ ...newAddress })
        setDeliveryAvailability(true)
        setMessage(data.message.replace('パラメータ「', '').replace('」', ''))
        setIsLoading(false)
      }
    }

    React.useEffect(() => {
      if (updateForm) {
        updateForm()
      }
    }, [address])

    const updateOther12 = e => {
      setAddress({
        ...address,
        other1_2: e.target.value,
      })
    }

    return (
      <S.Inner>
        <S.FormItem>
          <InputText
            required={phoneRequired}
            name="phone"
            defaultValue={address?.phone ?? ''}
            label={isGuest ? I18n.t('generic.mobile') : I18n.t('generic.phone')}
            error={errors.phone}
            onBlurHandler={removeDashValue}
            pattern={isGuest ? PHONE_INPUT_PATTERN : TEL_INPUT_PATTERN}
            readonly={isBundled}
          />
          {isGuest && (
            <S.FormItemNote>
              商品お届け時にこの番号宛に連絡が入りますので、当日連絡がつく番号を入力ください
            </S.FormItemNote>
          )}
          <S.FormItemNote>半角・ハイフンなしで入力してください</S.FormItemNote>
        </S.FormItem>
        {!isGuest && (
          <S.FormItem>
            <InputText
              name="emergency_phone"
              defaultValue={address?.emergency_phone ?? ''}
              label={'緊急連絡先'}
              error={errors.emergency_phone}
              // onChangeHandler={onChangeHandle}
              pattern={TEL_INPUT_PATTERN}
            />
            <S.FormItemNote>半角・ハイフンなしで入力してください</S.FormItemNote>
          </S.FormItem>
        )}
        <S.FormItem>
          <InputText
            name="land_line_phone"
            defaultValue={address?.land_line_phone ?? ''}
            label={'固定電話'}
            error={errors.land_line_phone}
            pattern={LAND_LINE_PHONE_INPUT_PATTERN}
            readonly={isBundled}
          />
          <S.FormItemNote>半角・ハイフンなしで入力してください</S.FormItemNote>
        </S.FormItem>

        <S.AddressFields id="map-input">
          <S.FormItem>
            <InputText
              required={required}
              name="postal_code1"
              defaultValue={address?.postal_code1 ?? ''}
              label={I18n.t('activerecord.attributes.address.postal_code')}
              error={errors.postal_code1}
              // onBlurHandler={onBlurHandle}
              onChangeHandler={onChangeHandle}
              readonly={isBundled}
            />
            <S.FormItemNote>半角・ハイフンなしで入力してください</S.FormItemNote>
          </S.FormItem>
          <S.FormItem>
            {!deliveryAvailability && (
              <S.FormItemImportantNote>配送範囲外です</S.FormItemImportantNote>
            )}
          </S.FormItem>
          {isLoading ? (
            <>
              <Spacer />
              <Spinner />
              <Spacer />
            </>
          ) : message ? (
            <>
              <S.FormItemImportantNote>{message}</S.FormItemImportantNote>
              <Spacer />
            </>
          ) : (
            <>
              <S.FormItem>
                <InputTextV2
                  required={required}
                  name="state1"
                  value={address?.state1 ?? ''}
                  label={I18n.t('activerecord.attributes.address.state')}
                  error={errors.state1}
                  readonly={disabledAddress}
                />
              </S.FormItem>
              <S.FormItem>
                <InputTextV2
                  required={required}
                  name="city1"
                  value={address?.city1 ?? ''}
                  label={I18n.t('activerecord.attributes.address.city')}
                  error={errors.city1}
                  readonly={disabledAddress}
                />
              </S.FormItem>
              <S.FormItem>
                <InputTextV2
                  required={required}
                  name="other1_1"
                  value={address?.other1_1 ?? ''}
                  label={I18n.t('activerecord.attributes.address.town_area')}
                  error={errors.other1_1}
                  readonly={disabledAddress}
                />
              </S.FormItem>
            </>
          )}
          <S.FormItem>
            <InputText
              required={required}
              name="other1_2"
              defaultValue={address?.other1_2 ?? ''}
              label={'その他の住所（部屋など）'}
              error={errors.other1_2}
              readonly={isBundled}
              onChangeHandler={updateOther12}
            />
          </S.FormItem>
        </S.AddressFields>
      </S.Inner>
    )
  }
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Inner = styled.div`
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: solid 1px ${COLORS.Border};
`

S.Hidden = styled.div`
  display: none;
`

S.FormItemNote = styled.p`
  color: #888;
  font-size: 12px;
  text-align: right;
  margin-top: 4px;
`

S.FormItemImportantNote = styled.p`
  color: ${COLORS.Danger};
  font-size: 16px;
  text-align: right;
  margin-top: 4px;
  font-weight: bold;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    text-align: left;
  }
`

S.Button = styled.div`
  margin-top: 12px;
`

S.AddressFields = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin-top: 16px;
`
S.Completed = styled.div`
  min-height: 2em;
  border-bottom: solid 1px ${COLORS.Border};
  &.updated {
    animation: flash ease-in ${FLASH_TIME}ms;
  }

  @keyframes flash {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
S.AutocompleteItems = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 16px 0;

  .FormLabel {
    font-size: 12px;
    font-weight: normal;
  }
`

S.Note = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`

S.TextArea = styled.textarea`
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background: none;

  &.error {
    border: 1px solid ${COLORS.Danger};
  }

  &:not([readonly]]):focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }

  &[readonly] {
    /* background-color: ${COLORS.Border}; */
    color: #888;
  }
`

S.Input = styled.input`
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background: none;

  &.error {
    border: 1px solid ${COLORS.Danger};
  }

  &:not([readonly]]):focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }

  &[readonly] {
    /* background-color: ${COLORS.Border};
    color: #888; */
  }
`

S.FormItem = styled.div`
  width: 100%;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  text-align: right;
  color: #777;
`

export default AddressForm
