import moment from 'moment'
import * as React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS } from '../../static/constants'
import FormItem from '../atoms/_layouts/FormItem'
import SelectOrder from '../atoms/SelectOrder'

interface IProps {
  name: string
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  deliveryTimeFrame?: string[]
  timeOptionsFilter?: (data: string, index: number, array: string[]) => boolean
  isOutsideRange?: (day) => boolean
  onChangeHandler?: (data) => void
  isDayBlocked?: (day) => boolean
  postalCode: string
}

const getIitializeValue = defaultValue => {
  if (defaultValue && defaultValue.length > 0) {
    const initDate = moment(defaultValue)
    const initDateTimeStr = initDate.format('YYYY-MM-DD HH:mm')
    const initDateStr = initDate.format('YYYY/MM/DD')
    const initTimeStr = initDate.format('HH:mm')

    return { initDate, initDateTimeStr, initDateStr, initTimeStr }
  }

  return { initDate: null, initDateTimeStr: '', initDateStr: null, initTimeStr: null }
}

const InputDateTimeOrder: React.FC<IProps> = ({
  name,
  defaultValue,
  label,
  error,
  required,
  deliveryTimeFrame,
  isOutsideRange,
  onChangeHandler,
  isDayBlocked,
  postalCode,
}) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState([])
  const { initDate, initDateTimeStr, initDateStr, initTimeStr } = getIitializeValue(defaultValue)
  const [selectedDate, setSelectedDate] = React.useState<null | moment.Moment>(initDate)
  const [selectedDateStr, setSelectedDateStr] = React.useState<null | string>(initDateStr)
  const [selectedTimeStr, setSelectedTimeStr] = React.useState<null | string>(initTimeStr)

  const [stringValue, setStringValue] = React.useState<string>(initDateTimeStr)
  // const options = formatBusinessTimeOptions(timeOptionsFilter)

  React.useEffect(() => {
    const {
      initDate: newInitDate,
      initDateTimeStr: newInitDateTimeStr,
      initDateStr: newInitDateStr,
      initTimeStr: newInitTimeStr,
    } = getIitializeValue(defaultValue)
    setSelectedDate(newInitDate)
    setSelectedDateStr(newInitDateStr)
    setSelectedTimeStr(newInitTimeStr)
  }, [defaultValue])

  React.useEffect(() => {
    if (selectedDateStr && selectedTimeStr) {
      setSelectedDate(moment(`${selectedDateStr} ${selectedTimeStr}`))
    } else if (selectedDateStr) {
      setSelectedDate(moment(selectedDateStr))
    }
  }, [selectedDateStr, selectedTimeStr])

  React.useEffect(() => {
    if (selectedDate) {
      if (onChangeHandler) {
        onChangeHandler(selectedDate)
      }

      setStringValue(selectedDate.format('YYYY-MM-DD HH:mm'))
    }
  }, [selectedDate])

  const setDate = (date: moment.Moment) => {
    setSelectedDateStr(date.format('YYYY-MM-DD'))
  }

  const setTime = (time: string) => {
    setSelectedTimeStr(time)
  }

  const [fetchError, setFetchError] = React.useState<string | null>(null)
  React.useEffect(() => {
    const fetchDeliveryCount = async () => {
      const allTimes = [
        { value: '11:00', label: '11:00-13:00' },
        { value: '15:00', label: '15:00-18:00' },
        { value: '19:00', label: '19:00-20:00' },
      ]
      const times = allTimes.filter(time => deliveryTimeFrame.includes(time.value))

      try {
        if (selectedDateStr) {
          const updatedTimes = await Promise.all(
            times.map(async time => {
              const response = await fetch(
                `/api/reservations/delivery_count?date_time=${selectedDateStr} ${time.value}&postal_code=${postalCode}`
              )
              if (!response.ok) {
                throw new Error(`Failed to fetch delivery count: ${response.statusText}`)
              }
              const data = await response.json()
              return {
                ...time,
                orderCount: data.count,
                status: data.status,
                deliveryLimit: data.delivery_limit,
              }
            })
          )

          // 配送可能な時間枠のみフィルタリング
          const filteredTimes = updatedTimes.filter(time => time.orderCount < time.deliveryLimit)
          setOptions(filteredTimes)

          // 選択済みの時間がフィルタリング後に存在しない場合、リセットする
          if (!filteredTimes.find(time => time.value === selectedTimeStr)) {
            if (filteredTimes.length > 0) {
              setTime(filteredTimes[0].value)
            } else {
              setTime(null)
              setSelectedTimeStr(null)
            }
          }

          setFetchError(null)
        }
      } catch (err) {
        console.error(err)
        setFetchError(err.message)
      }
    }

    fetchDeliveryCount()
  }, [selectedDateStr, postalCode, deliveryTimeFrame])

  return (
    <>
      {fetchError ? (
        <S.ErrorMessage>配送時間の取得に失敗しました</S.ErrorMessage>
      ) : options.length === 0 ? (
        <S.ErrorMessage>選択した日付には利用可能な配送時間がありません</S.ErrorMessage>
      ) : (
        <FormItem label={label} required={required} error={error}>
          <S.Relative>
            <S.Wrapper>
              <S.Wrapper style={{ marginRight: '16px' }}>
                <S.Label>日付</S.Label>
                <SingleDatePicker
                  date={selectedDate}
                  onDateChange={date => setDate(date)}
                  focused={isFocused}
                  isOutsideRange={isOutsideRange}
                  isDayBlocked={isDayBlocked}
                  onFocusChange={({ focused }) => setIsFocused(focused)}
                  numberOfMonths={1}
                  readOnly={true}
                />
              </S.Wrapper>
              <S.Wrapper>
                <S.Label>時間</S.Label>
                <SelectOrder
                  name=""
                  options={options}
                  defaultValue={selectedTimeStr}
                  onChangeHandler={e => {
                    setTime(e.target.value)
                  }}
                />
              </S.Wrapper>
            </S.Wrapper>
            <S.Input
              id={name}
              type="text"
              name={name}
              value={stringValue}
              required={required ? true : false}
              ref={null}
              readOnly={false}
            />
          </S.Relative>
        </FormItem>
      )}
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Relative = styled.div`
  position: relative;
`
S.Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  .DateInput_input {
    font-weight: normal;
    font-size: 16px;
    padding: 8px 12px;
  }
  select {
    min-width: 132px;
    height: 44px;
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }
`
S.Label = styled.div``

S.Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
  top: 45px;
  left: 130px;

  &[readonly] {
    background-color: ${COLORS.Border};
  }
`

S.ErrorMessage = styled.div`
  margin-top: 8px;
  color: ${COLORS.Danger};
  font-weight: bold;
  font-size: 16px;
`
export default InputDateTimeOrder
